import { z } from 'zod';

export const FeatureFlagNameSchema = z.enum([
  // Testing
  'test-flag',
  // Kill switches
  'disable-schedule-apply-buttons',
  'disable-slack-notifications-v2',
  'disable-slack-status-sync-v2',
  'disable-scenario-planning',
  'disable-slack-notifications',
  'send-slack-notification-on-publish',
  'enable-ical-export',
  'enable-gcal',
  'disable-gcal-webhook',
  'enable-out-of-hours-events',
  'disable-push-to-gcal',
  'stop-sending-schedule-block-events-to-pubsub',
  'disable-merge-time-off-sync',
  'disable-aircall-webhook-timeline-builder',
  'disable-timeline-builder',
  'kill-switch-aircall-event-processing',
  'kill-switch-trigger-timeline-builder-on-schedule-changes',
  'kill-switch-talkdesk-performance',
  'kill-switch-dialpad-performance',
  'kill-switch-dialpad-reports',
  'kill-switch-dialpad-sms-events',
  'kill-switch-dialpad-streams-sync',
  'kill-switch-new-kustomer-data',
  'kill-switch-kustomer-webhooks-health-check',
  // Forecasting
  'disable-forecasting-page', // hide the forecasting page - this is used for hiding forecasting pages and other that requires forecasting data when a customer has a "starter plan" which doesn't include this feature.
  'adjust-staffing-requirement-for-low-ticket-volume',
  'front-fetch-inboxes',
  'forecasting-see-year',
  // Holiday handling
  'time-off-in-surfer-timezone',
  'handle-half-day-holidays',
  'time-off-match-on-surfer-first-name-only',
  'time-off-match-on-surfer-first-name-last-initial',
  'show-calendar-state',
  // Insights
  'insights-week-view',
  'insights-month-view',
  'insights-fte',
  'insights-coverage',
  'insights-surfer-select',
  // Performance
  'show-performance-feedback-button',
  'show-shift-feedback',
  'performance-page-show-today',
  'show-performance-current-status',
  'enable-salesforce-performance',
  'disable-ticket-sessions',
  'enable-new-performance',
  'enable-new-performance-surfer-app',
  'enable-new-surfer-performance',
  'timeline-builder-channel-level-adherence',
  'snapshots-ticket-status-logs',
  'hide-performance-from-surfers',
  'hide-performance-from-managers',
  'task-ait-metric-enabled',
  'task-act-metric-enabled',
  'use-precalculated-time-on-task',
  // Real time
  'real-time-page',
  'real-time-promo-banner',
  'real-time-team-distribution',
  'disable-real-time-team-distribution-status',
  'time-on-task-status-changed-at',
  'time-on-task-status-hover',
  'real-time-nudge-surfer',
  // Scenario planning
  'scenario-planning-page',
  'scenario-backlog-prediction',
  // Schedule
  'show-clear-schedule-button',
  'disable-surfer-working-hours',
  'fast-publish',
  'hide-shrink-to-cover-toggle', // on for one org in production
  'email-surfer-on-schedule-change-request',
  'enable-rule-conditions',
  'enable-rule-min-session-length',
  'enable-forecast-rule',
  'enable-forecast-rule-loop',
  'enable-schedule-refresh',
  'schedule-on-call-fill-shifts',
  'enable-exclusive-projection-interval',
  'auto-schedule-shifts-button',
  'shift-coverage-chart',
  'week-auto-schedule-shifts',
  'use-shifts-endpoint', // only return shifts from the blocks endpoint
  'show-new-shift-view-toggle', // shows a toggle to switch between the current shift view and the WIP
  'kill-switch-send-schedule-event-messages',
  // Settings
  'billing-page-disabled',
  'show-connections-page-v2', // never enabled in prod - discuss in #product
  // forecasting
  'enable-ts-erlang-forecasting',
  'forecasting-send-configuration',
  'run-forecasting-in-worker',
  // Misc
  'halloween', // disabled for all
  'disable-permissions',
  'enable-talkdesk',
  'count-messages-for-async-intercom-channels',
  'dixa-conversations-messages-count-split',
  'kustomer-conversations-messages-count-split',
  'fallback-to-fetch-when-merge-is-broken',
  'enterprise-auth-migration',
  'hide-profile-pictures',
  // ADP
  'enable-adp-linking',
  // Calendar
  'enable-schedule-into-primary-calendar',
  'enable-pushing-events-for-tasks',
  'enable-pushing-events-for-shifts',
  'enable-calendar-into-schedule',
  'enable-gcal-logging',
  'enable-new-date-gcal-check',
  'enable-avoid-insert-blocked-if-same-gcal-exists',
  'recreate-block-events-from-gcal',
  // Timesheets
  'show-time-off-in-timesheet',
  // Activity plan
  'activity-webworker',
  'activity-hide-coverage',
  'show-timeoff-subtypes',
  'total-time-sub-15-min-sessions',
  'enable-total-time-dispersion',
  'coverage-rule-fairness',
  'breaks-dynamic-windows',
  'dynamic-fill-shift-rule',
  // onboarding/offboarding surfers
  'onboarding-offboarding-surfers',
  'offboarding-delete-surfers-cron',
  'onboarding-invite-surfers',
  // time off
  'force-merge-sync',
  'edit-time-off',
  'add-time-off',
  // dialpad sync
  'enable-cron-sync-dialpad-agents',
  'merge-time-off-pubsub',
]);
export type FeatureFlagName = z.infer<typeof FeatureFlagNameSchema>;

const FeatureFlagSchema = z.object({
  name: FeatureFlagNameSchema,
  enabled: z.boolean(),
});

export type FeatureFlag = z.infer<typeof FeatureFlagSchema>;

// Allow any string for stale client bundles
export const CheckFeatureFlagRespSchema = FeatureFlagSchema.extend({
  name: z.string(),
});
export type CheckFeatureFlagResp = z.infer<typeof CheckFeatureFlagRespSchema>;

export const FeatureFlagsRespSchema = z.record(FeatureFlagNameSchema.or(z.string()), z.boolean());
export type FeatureFlagsResp = z.infer<typeof FeatureFlagsRespSchema>;
